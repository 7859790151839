import { AppBar, Button, IconButton, Link, Menu, MenuItem, Popover, Toolbar } from "@mui/material";
import Typography from "@mui/material/Typography";
import MenuIcon from '@mui/icons-material/Menu';
import { useState } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import Login from "./login";
import Auth from "./auth";

const Header: React.FC  = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [loginOpen, setLoginOpen] = useState(false);
  const [loginAnchorEl, setLoginAnchorEl] = useState<null | HTMLElement>(null);
  const { user, signOut } = useAuthenticator((context) => [context.user]);

  const handleClose = () => {
    setMenuOpen(false);
    setAnchorEl(null);
  }

  const handleLoginClose = () => {
    setLoginOpen(false);
    setLoginAnchorEl(null);
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if(!menuOpen){
        setAnchorEl(event.currentTarget);
        setMenuOpen(true);
    }
  } 

  const handleLoginClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if(!loginOpen){
        setLoginAnchorEl(event.currentTarget);
        setLoginOpen(true);
    }
  }

    return (
        <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={handleClick}
          >
            <MenuIcon />
            <Menu anchorEl={anchorEl} open={menuOpen} onClose={handleClose}>
                <MenuItem>
                  <Link role="menuitem" href="/howtoplay">How to Play</Link>
                </MenuItem>
            </Menu>
          </IconButton>
          <Button color="inherit" href="/" sx={{textTransform: "none"}}>
            <Typography variant="h5">
              Famous Initials
            </Typography>
          </Button>
          <Button sx={{marginLeft: "auto"}} color="inherit" onClick={handleLoginClick}>Login / Register</Button>
          <Popover
            open={loginOpen}
            anchorEl={loginAnchorEl}
            onClose={handleLoginClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            >
            <Login />
            </Popover>
        </Toolbar>
      </AppBar>
  );
}

export default Header;